//import "./watermark_theme.bundle";
let font_size, text, light_color,line_space,
	light_opacity, dark_opacity, dark_color,
	rotate;
let modules = [];
let loaded = false;
let enable = false;
async function getWatermarkSetting() {
	const watermark = await frappe.db.get_doc('Zelin Watermark Setting');
	({ font_size, line_space = 100, text='', light_color='#F5F5F5', light_opacity=0.5, dark_color='#F5F5F5', dark_opacity=0.5, rotate, enable } = watermark || {});
	if (watermark && watermark.enable_doctype){
		modules = watermark.enable_doctype.map(item => item.module) || [];
	}
	const companyName = frappe.sys_defaults.company;
	const userName = frappe.session.user_fullname;
    font_size = font_size?font_size:10;
	text = text ? text.replaceAll('{{company}}', companyName) : '';
	text = text ? text.replaceAll('{{user}}', userName) : '';
	text = text || `${companyName} ${userName}`;
	loaded = true;
}
async function watermarkApply() {
	if (loaded === false) {
		await getWatermarkSetting();
	}
	const body = document.getElementById('body');
	const bg_id = 'watermark_theme_bg';
	const bg = document.getElementById(bg_id);
	if (bg) { body && body.removeChild(bg); }
	if (!enable) { return; }
	// if (modules.length !== 0 && !modules.some(item => window.location.href.includes(item.toLocaleLowerCase().replaceAll(' ', '-')))) {
	// 	body.oncopy = null;
	// 	return;
	// }
	// body.oncopy = e => {
	// 	e.clipboardData.setData('text/plain', '');
	// 	return false;
	// };
	//const theme = document.documentElement.dataset.themeMode || 'light';
	// version 13
	const theme = document.documentElement.getAttribute("data-theme") || "light"
	let color;
	let opacity;
	if (theme === 'light') {
		color = light_color;
		opacity = light_opacity;
	} else {
		color = dark_color;
		opacity = dark_opacity;
	}
	const el = document.createElement('div');
	el.innerHTML = text;
	el.style.height = '0';
	el.style.fontSize = `${font_size || 10}px`;
	el.style.display = 'inline-block';
	body.append(el);
	const width = el.offsetWidth + 50;
	body.removeChild(el);

	const svgNS = 'http://www.w3.org/2000/svg';
	const svg = document.createElementNS(svgNS, 'svg');
	svg.setAttribute('xmlns', svgNS);
	svg.setAttribute('width', `${width || 300}px`);
	svg.setAttribute('height', `${line_space || 100}px`);

	const txt = document.createElementNS(svgNS, 'text');
	txt.setAttribute('x', '0');
	txt.setAttribute('y', ((font_size) * 1.2).toString());
	txt.setAttribute('font-size', `${font_size}`);
	txt.setAttribute('fill', color);
	txt.setAttribute('text-anchor', 'start');
	txt.innerHTML = text;

	svg.appendChild(txt);

	const div = document.createElement('div');
	div.id = bg_id;
	div.style.opacity = opacity.toString();
	const _svg = encodeURIComponent(svg.outerHTML);
	const bg_str = `url(data:image/svg+xml,${_svg})`;
	div.style.background = bg_str;
	div.style.position = 'fixed';
	div.style.zIndex = '999999999999999';
	div.style.userSelect = 'none';
	div.style.pointerEvents = 'none';
	div.style.top = '-50vh';
	div.style.left = '-50vw';
	div.style.height = '200vh';
	div.style.width = '200vw';
	div.style.transform = `rotate(${rotate}deg)`;
	body.append(div);
}
$(document).on('app_ready', async function () {
	watermarkApply();
});

const observer = new MutationObserver(() => {
	watermarkApply();
});
const root = document.documentElement;
observer.observe(root, {
	attributes: true,
	attributeFilter: ['data-theme-mode'],
});

window.addEventListener('popstate', function (event) {
	watermarkApply();
});

const rewriteHistory = function (type) {
	const originFunc = history[type];
	const listenerEvent = new Event(type);
	return function () {
		const originResult = originFunc.apply(this, arguments);
		window.dispatchEvent(listenerEvent);
		return originResult;
	};
};
history.pushState = rewriteHistory('pushState');
history.replaceState = rewriteHistory('replaceState');

window.addEventListener('replaceState', function (e) {
	watermarkApply();

});
window.addEventListener('pushState', function (e) {
	watermarkApply();
});